import { useState, useEffect, useCallback, } from 'react';

const SCREEN_IMAGE = 'view';
const SCREEN_UPLOAD = 'editing';
const SCREEN_CROP = 'cropping';

const useCropAndUpload = ({ initialUrl, onChange }) => {
  const [currentScreen, setScreen] = useState(null);

  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);
  const [uploaded, setUploaded] = useState(false);

  const isEditing = currentScreen === SCREEN_UPLOAD;
  const isCropping = currentScreen === SCREEN_CROP;

  const onCropComplete = useCallback((files) => {
    const { url: cropUrl } = files[0];

    setUploaded(false);
    setUrl(cropUrl);
  }, []);

  const onError = useCallback((err) => setError(err), []);

  const onUploadComplete = useCallback((files) => {
    setUploaded(true);
    setUrl(files[0].url);
  }, []);

  const onUploadStart = useCallback(() => {
    setError(null);
    setUrl(null);
  }, []);

  const setEditing = useCallback((value) => {
    setScreen(value ? SCREEN_UPLOAD : SCREEN_IMAGE)
  }, []);

  useEffect(() => {
    if (uploaded) {
      setScreen(SCREEN_CROP);
    } else if (initialUrl && initialUrl === url && !/\-crop\..*/.test(url)) {
      setScreen(SCREEN_CROP);
    } else if (!url) {
      setScreen(SCREEN_UPLOAD);
    } else {
      setScreen(SCREEN_IMAGE);
    }
  }, [initialUrl, url, uploaded]);

  useEffect(() => {
    setUrl(initialUrl);
  }, [initialUrl]);

  useEffect(() => {
    onChange(url, !isEditing && !isCropping);
  }, [url, isEditing, isCropping]);

  return {
    error,
    url,
    isCropping,
    isEditing,
    setEditing,
    onError,
    onCropComplete,
    onUploadStart,
    onUploadComplete
  }
};

export default useCropAndUpload;
