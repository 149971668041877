import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';

import React from 'react';
import ReactDOM from 'react-dom';
import ReactTags from 'react-tag-autocomplete';

class Autocomplete extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      tags: JSON.parse(props.tags) || [],
      suggestions: JSON.parse(props.suggestions) || [],
      busy: false,
    };
  }

  handleInputChange(input) {
    if (!this.state.busy && this.props.query_url) {
      this.setState({ busy: true });
      return fetch(`${this.props.query_url}?query=${input}`, {
        credentials: 'same-origin'
      }).then(response => response.json())
        .then(data => this.setState({ suggestions: data, busy: false }));
    }
  }

  handleDelete (i) {
    // remove from a hidden form field
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  handleAddition (tag) {
    // add to a hidden form field
    const tags = [].concat(this.state.tags, tag);
    this.setState({ tags });
  }

  render () {
    return (
      <div>
        <ReactTags
          tags={this.state.tags}
          suggestions={this.state.suggestions}
          handleDelete={this.handleDelete.bind(this)}
          handleAddition={this.handleAddition.bind(this)}
          handleInputChange={this.handleInputChange.bind(this)}
          allowNew={true}
          delimiters={[9,13]}
          delimiterChars={[',']}
          inline
          placeholder={ this.state.tags.length > 0 ? '' : `Add ${this.props.unit}` }
          classNames={{
            root: 'react-tags',
            rootFocused: 'is-focused',
            selected: 'react-tags__selected',
            selectedTag: 'react-tags__selected-tag',
            selectedTagName: 'react-tags__selected-tag-name',
            search: 'react-tags__search',
            searchInput: 'react-tags__search-input',
            suggestions: 'react-tags__suggestions',
            suggestionActive: 'is-active',
            suggestionDisabled: 'is-disabled'
          }}
        />
        {this.state.tags &&
          this.state.tags.map((tag, index) =>
            <input
              id={this.props.field}
              key={index}
              name={this.props.field}
              type={"hidden"}
              value={tag.id || tag.name} />
        )}
      </div>
    );
  }
}

const containers = document.querySelectorAll('.autocomplete');

Array.prototype.forEach.call(containers, container => {
  ReactDOM.render(<Autocomplete {...(container.dataset)} />, container);
});
