import React, { Fragment } from 'react';

class GistEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.state = { link: props.link }
  }

  extract_id(url) {
    try {
      return url.match(/https:\/\/gist.github.com\/.*?\/([a-z0-9]+)[\.js.*|\/.*|\?.*]?/).pop();
    } catch(error) {
      return "";
    }
  }

  update(e) {
    this.setState({ link: this.extract_id(e.target.value) }, () => {
      this.props.onChange(this.state.link);
    })
  }

  value() {
    if (this.state.link) {
      return `https://gist.github.com/${this.state.link}`;
    } else {
      return "";
    }
  }

  onKeyDown(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      this.props.done();
    }
  }

  render() {
    return (
        <Fragment>
          {this.props.isEditing ?
            <Fragment>
              <span className="heading-6 mb--0">Gist Code</span>
              <span className="builder-card-heading">Add a GitHub Gist link to share your code.</span>
              <div className="field">
                <input
                  className="field-input"
                  onKeyDown={this.onKeyDown.bind(this)}
                  onChange={(e) => this.update(e) }
                  placeholder="https://gist.github.com/e330f122b1993acfdde4ff873bc8c7s5"
                  value={this.value()}
                />
              </div>
              {this.props.content !== '' &&
                <div className="field-actions">
                  <button
                    className="button button--minWidth button--secondary"
                    onClick={this.props.done}
                  >Done</button>
                </div>
              }
            </Fragment>
            :
            <div className="builder-embedGist">
              <iframe src={`data:text/html;charset=utf-8,%3Cbody%3E%3Cstyle%3Ebody%7Bmargin%3A0%3B%7Ddiv%20div.gist-file%3Alast-child%7Bmargin-bottom%3A0%3B%7D%3C%2Fstyle%3E%3Cscript%20src%3D%22https%3A%2F%2Fgist.github.com%2F${this.state.link}.js%22%3E%3C%2Fscript%3E%3C%2Fbody%3E`} />
            </div>
          }
        </Fragment>
    );
  }
};

GistEmbed.TYPE = 'gist-embed';
GistEmbed.initial = () => ({link: '', type: GistEmbed.TYPE});
GistEmbed.update = (medium, value) => medium.link = value;

export default GistEmbed;
