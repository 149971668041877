import PhotoSwipeLightbox from 'photoswipe/dist/photoswipe-lightbox.esm.js';
import PhotoSwipe from 'photoswipe/dist/photoswipe.esm.js';

// -------------------------------------
//   PhotoSwipe
// -------------------------------------

export default () => {
  const template = document.querySelector('.pswp');
  const thumbnails = document.querySelectorAll('.photoswipe-trigger');
  const config = {
    bgOpacity: 0.95,
    showHideAnimationType: 'none',
    pswpModule: PhotoSwipe,
  };

  const dataSource = [];

  if (thumbnails.length === 0 || !template) return false;

  const lightbox = new PhotoSwipeLightbox(config);
  lightbox.init();

  lightbox.on('loadComplete', function(event) {
    if (event.content.height === 0 || event.content.width === 0) {
      const pswp = lightbox.pswp;
      const image = event.content.element;
      const index = +event.content.index;

      pswp.options.dataSource[index].w = dataSource[index].w = image.naturalWidth;
      pswp.options.dataSource[index].h = dataSource[index].h = image.naturalHeight;
      pswp.refreshSlideContent(index);
    }
  });

  Array.prototype.forEach.call(thumbnails, function(el, index) {
    el.setAttribute('data-ps-id', index);
  });

  thumbnails.forEach(function(el) {
    const image = el.querySelector('img');

    // update the image dimensions in the dataSource
    image.addEventListener('load', function() {
      const index = +el.getAttribute('data-ps-id');
      dataSource[index].w = image.naturalWidth;
      dataSource[index].h = image.naturalHeight;
    });

    dataSource.push({
      srcset: image.getAttribute('data-srcset') || image.getAttribute('srcset') || undefined,
      src: image.getAttribute('data-src') || image.getAttribute('src') || undefined,
      alt: image.getAttribute('alt'),
      w: image.naturalWidth > 0 ? image.naturalWidth : undefined,
      h: image.naturalHeight > 0 ? image.naturalHeight : undefined,
    });
  });

  document.body.addEventListener('click', function(e) {
    const $ele = e.target.classList.contains('photoswipe-trigger') ? e.target : e.target.closest('.photoswipe-trigger');

    if ($ele) {
      e.preventDefault();

      lightbox.loadAndOpen(+$ele.getAttribute('data-ps-id'), dataSource);
    }
  });
};
