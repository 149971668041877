import React, { Fragment, useEffect } from 'react';

const Spacer = ({ isEditing, done }) => {
  useEffect(done, []);

  return (
    <Fragment>
      <div className="builder-spacer">
        <span>Empty Space</span>
      </div>
      {isEditing &&
        <div className="field-actions">
          <button
            className="button button--minWidth button--secondary"
            onClick={done}
          >
            Keep spacer
          </button>
        </div>
      }
    </Fragment>
  );
}

Spacer.TYPE = 'spacer';
Spacer.initial = () => ({ content: ' ', type: Spacer.TYPE });
Spacer.update = (medium, value) => medium.content = value;

export default Spacer;
