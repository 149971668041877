import React from 'react';
import ReactDOM from 'react-dom';

import MediaBuilder from 'src/media_builder';

const domContainer = document.querySelector('#builder-container');
const projectMedia = document.querySelector('#entry_media');

if (projectMedia && domContainer) {
  const media = JSON.parse(projectMedia.value);
  const onChange = ({media}) => projectMedia.value = JSON.stringify(media);

  ReactDOM.render(<MediaBuilder entry={true} media={media} onChange={onChange} username={domContainer.dataset.username} wip={domContainer.dataset.wip === 'true'} />, domContainer);
}
