import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import cropImage from 'src/helpers/crop-image';
import upload from 'src/helpers/upload';

const CropUploader = ({ aspect, url, onChange }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = crop => setCrop(crop);
  const onZoomChange = zoom => setZoom(zoom);
  const onCropComplete = (croppedArea, pixels) => setCroppedAreaPixels(pixels);

  const onDone = () => {
    cropImage(url, croppedAreaPixels)
      .then((croppedImage) => upload([croppedImage]).then(onChange));
  };

  const cacheBuster = `${aspect}${crop.x}${crop.y}${zoom}`;

  return (
    <div className="grid-box">
      <div className="crop">
        <Cropper
          image={url + '?' + cacheBuster}
          crop={crop}
          crossOrigin="anonymous"
          zoom={zoom}
          aspect={aspect}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={onZoomChange}
        />
      </div>
      <div className="bucket bucket--spacing crop-slider">
        <input
          type="range"
          min="1"
          max="3"
          step="0.1"
          value={zoom}
          onChange={e => onZoomChange(e.target.value)}
          className="crop-slider-input"
        />
        <button
          className="button button--secondary button--auto"
          type="button"
          onClick={onDone}>Done</button>
      </div>
    </div>
  );
};

export default CropUploader;
