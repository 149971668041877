import React from 'react';
import ReactDOM from 'react-dom';

import { ImageError, DropUploader, CropUploader, Icon } from 'src/components';

class Uploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: false,
      error: null,
      limit: 1,
      url: this.props.url,
    };
    this.onCropComplete = files => {
      const url = files[0].url;
      this.setState({ url: url, crop: false });
      this.props.onChange(url);
    };
    this.onError = (error) => {
      this.setState({error});
    };
    this.onUploadComplete = files => {
      const url = files[0].url;
      this.setState({ url: url, crop: true });
      this.props.onChange(url);
    };
    this.onUploadStart = () => {
      this.setState({error: null, url: null});
    };
  }

  render() {
    return (
      <div className="grid grid--flush">
        {!this.state.crop &&
          <React.Fragment>
            <div className="grid-box--3 grid-box--S--2">
              {this.state.url &&
                <img src={this.state.url} />
              }
              {!this.state.url &&
                <div className="profile-upload-empty">
                  <Icon symbol="image" />
                </div>
              }
            </div>

            <div className="grid-box--9 grid-box--S--10 bucket">
              {this.state.error &&
                <ImageError message={this.state.error.message} />
              }

              <DropUploader
                label={this.state.url ? 'Replace Cover Image' : 'Upload Cover Image'}
                limit={this.state.limit}
                onChange={this.onUploadComplete}
                onError={this.onError}
                onUploadStart={this.onUploadStart}
                small={true}
                suffix={this.props.username}
              />
            </div>
          </React.Fragment>
        }

        {this.state.crop &&
          <CropUploader
            aspect={4/3}
            onChange={this.onCropComplete}
            url={this.state.url}
          />
        }
      </div>
    );
  }
};

const containers = document.querySelectorAll('.cover-uploader-container');

Array.prototype.forEach.call(containers, container => {
  const target = document.querySelector(container.getAttribute('data-target'));
  const username = container.getAttribute('data-username');
  if (target) {
    const onChange = url => target.value = url;
    ReactDOM.render(<Uploader onChange={onChange} url={target.value} username={username} />, container);
  }
});
