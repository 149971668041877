import React from 'react';

import WorkExperience from './work-experience';

const sortExperiences = ({ from_year: a }, { from_year: b }) => {
  return parseInt(a || 0) < parseInt(b || 0) ? 1 : -1;
};

class WorkExperiences extends React.Component {
  constructor(props) {
    super(props);
    this.state = { workExperiences: props.workExperiences.sort(sortExperiences) };
  }

  addWork() {
    this.setState(state => state.workExperiences.push({}));
  }

  errorsFor(index) {
    const data = this.props.errors[this.props.prefix] || {};
    const workExperiences = data['work_experiences'] || [];
    return workExperiences[index] || {};
  }

  remove(index) {
    this.setState(state => {
      state.workExperiences.splice(index, 1);
      return state;
    });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.workExperiences.map((item, index) => {
          return (
            <WorkExperience
              data={item}
              errors={this.errorsFor(index)}
              index={index}
              key={index}
              remove={e => this.remove(index)} />
          );
        })}
        <a className="profile-web-new" onClick={e => this.addWork()}>+ Add Experience</a>
      </React.Fragment>
    );
  }
}

export default WorkExperiences;
