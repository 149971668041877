export default (el) => {
  const images = el.querySelectorAll('.builder-process-image');
  const indicator = el.querySelector('.builder-process-indicator');
  const range = el.querySelector('.builder-process-range-input');

  if (!range) { return []; }

  range.setAttribute('data-steps', images.length);

  const callback = function() {
    const steps = images.length - 1;
    const stepWidth = 100 / steps;

    Array.prototype.forEach.call(images, function(image, index) {
      const opacity = range.value / stepWidth - (index - 1);
      image.style.opacity = opacity;
      if (opacity > 0) {
        image.style.zIndex = index + 1;
      } else {
        image.style.zIndex = 0;
      }
    });
  };

  const callbackMousedown = function() {
    indicator.classList.add('is-hidden');
  }

  const callbackMouseup = function() {
    indicator.classList.remove('is-hidden');
  }

  callback();
  range.addEventListener('input', callback);
  range.addEventListener('mousedown', callbackMousedown);
  range.addEventListener('mouseup', callbackMouseup);
  return [range, callback];
}
