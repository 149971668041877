import React from 'react';
import { Icon } from 'src/components';

const Narrow = ({ narrow, toggleNarrow }) => (
  <label
    className="buttonCircle buttonCircle--S buttonCircle--secondary buttonCircle--shadow builder-imageAction"
    onClick={toggleNarrow}
  >
    <Icon symbol={narrow ? 'expand' : 'contract'} />
    <div className="buttonCircle-tooltip">{narrow ? 'Make Full-Width' : 'Make Narrow'}</div>
  </label>
);

export default Narrow;
