import React, { Fragment, useState } from 'react';
import { classNames, ImagesEdit, ImageError, DropUploader } from 'src/components';
import Narrow from './narrow';

const UPLOAD_LIMIT = 10;

const ImageColumn = ({
  done,
  isEditing,
  onChange,
  removeItem,
  sortItem,
  username,
  /* Object Params */
  type,
  narrow: isNarrow,
  urls: urlCollection,
  url: legacyUrl,
}) => {
  const [errorNotice, setError] = useState(null);

  const onUploadStart = () => {
    setError(null);
    // done();
  };

  const urls = typeof(legacyUrl) !== 'undefined' && typeof(urlCollection) === 'undefined' ? [legacyUrl] : urlCollection;

  const displayClassCss = classNames({
    'builder-image': true,
    'builder-narrow': isNarrow,
    'is-hidden': isEditing,
  });

  return (
    <Fragment>
      {isEditing &&
        <Fragment>
          <span className="heading-6 mb--0">Image</span>
          <span className="builder-card-heading">Add between 1-{UPLOAD_LIMIT} images in a single stacked column.</span>
          <ImagesEdit onSortEnd={sortItem} removeItem={removeItem} urls={urls} />
        </Fragment>
      }
      {!isEditing &&
        <Narrow
          narrow={isNarrow}
          toggleNarrow={() => onChange({ narrow: !isNarrow })}
        />
      }

      {errorNotice &&
        <ImageError message={errorNotice.message} />
      }

      {isEditing && urls.length < UPLOAD_LIMIT &&
        <DropUploader
          label='Add Images'
          limit={UPLOAD_LIMIT - urls.length}
          numFiles={urls.length}
          onChange={(files) => onChange({ files })}
          onError={(error) => setError(error)}
          onUploadStart={onUploadStart}
          suffix={username}
        />
      }

      {isEditing && urls.length !== 0 &&
        <div className="field-actions">
          <button
            className="button button--minWidth button--secondary"
            onClick={done}
          >
            Done
          </button>
        </div>
      }


      {urls.map((url, index) =>
        <div
          key={`image-item-${index}`}
          className={displayClassCss}
        >
          <div>
            <img src={url} />
          </div>
        </div>
      )}
    </Fragment>
  );
};

ImageColumn.TYPE = 'image';
ImageColumn.initial = () => ({ urls: [], narrow: true, type: ImageColumn.TYPE });
ImageColumn.update = (medium, { files, narrow }) => {
  if (medium.url) { /* Remove legacy shit */
    medium.urls = [medium.url];
    delete medium.url;
  }

  if (typeof(narrow) !== 'undefined') {
    medium.narrow = narrow;
  }

  if (files) {
    files.map(f => medium.urls.push(f.url))
  }
}

export default ImageColumn;
