const builderRowResize = (image) => {
  const height = image.naturalHeight;
  const width = image.naturalWidth;
  image.parentElement.style.flex = width / height;
}

const builderRowCallback = (image) => {
  if (image.naturalHeight === 0) {
    setTimeout(() => builderRowCallback(image), 100);
  } else {
    builderRowResize(image);
  }
}

export default (el) => {
  const images = el.querySelectorAll('img');
  Array.prototype.forEach.call(images, function(image) {
    image.onload = builderRowCallback(image);
  });
}
