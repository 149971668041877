import React from 'react';

const MONTHS = [
  ['Jan', 'January'],
  ['Feb', 'February'],
  ['Mar', 'March'],
  ['Apr', 'April'],
  ['May', 'May'],
  ['Jun', 'June'],
  ['Jul', 'July'],
  ['Aug', 'August'],
  ['Sep', 'September'],
  ['Oct', 'October'],
  ['Nov', 'November'],
  ['Dec', 'December']
];

const YEARS = (year => {
  const endYear = 1900;
  let years = [];

  while (year >= endYear) {
    years.push(year--);
  }

  return years;
})(new Date().getFullYear());

class WorkExperience extends React.Component {
  constructor(props) {
    super(props);
    this.state = props.data;
  }

  errorFor(attr) {
    return (this.props.errors[attr] || []).join(', ');
  }

  hasErrorFor(attr) {
    return this.errorFor(attr).length > 0;
  }

  idFor(attr) {
    return `member-work-experiences-${this.props.index}-${attr}`;
  }

  nameFor(attr) {
    return `member[work_experiences][][${attr}]`;
  }

  render() {
    return (
      <div className="profile-work">
        <div className={`field${this.hasErrorFor('title') ? ' is-erred' : ''}`}>
          <label className="field-label" htmlFor={this.idFor('title')}>Title</label>
          <input className="field-input" required defaultValue={this.state.title} id={this.idFor('title')} name={this.nameFor('title')} />
          {this.hasErrorFor('title') &&
            <span className="field-message field-message--error">{this.errorFor('title')}</span>}
        </div>
        <div className="profile-work-bucket">
          <div className={`field field--fullWidth${this.hasErrorFor('url') ? ' is-erred' : ''}`}>
            <label className="field-label" htmlFor={this.idFor('url')}>Website</label>
            <input className="field-input" defaultValue={this.state.url} id={this.idFor('url')} name={this.nameFor('url')} />
            {this.hasErrorFor('url') &&
              <span className="field-message field-message--error">{this.errorFor('url')}</span>}
          </div>
          <div className={`field field--fullWidth${this.hasErrorFor('location') ? ' is-erred' : ''}`}>
            <label className="field-label" htmlFor={this.idFor('location')}>Location</label>
            <input className="field-input" defaultValue={this.state.location} id={this.idFor('location')} name={this.nameFor('location')} />
            {this.hasErrorFor('location') &&
              <span className="field-message field-message--error">{this.errorFor('location')}</span>}
          </div>
        </div>
        <div className="profile-work-bucket">
          <div className={`field field--fullWidth${this.hasErrorFor('from_month') || this.hasErrorFor('from_year') ? ' is-erred' : ''}`}>
            <label className="field-label" htmlFor={this.idFor('from-year')}>Start Date</label>
            <div className="bucket bucket--spacing bucket--start">
              <div className="field-select field-select--fullWidth">
                <select defaultValue={this.state.from_year} id={this.idFor('from-year')} name={this.nameFor('from_year')}>
                  <option value="">Year</option>
                  {YEARS.map((value, idx) => <option key={idx} value={value}>{value}</option>)}
                </select>
                {this.hasErrorFor('from_year') &&
                  <span className="field-message field-message--error">{this.errorFor('from_year')}</span>}
              </div>
              <div className="field-select field-select--fullWidth">
                <select defaultValue={this.state.from_month} id={this.idFor('from-month')} name={this.nameFor('from_month')}>
                  <option value="">Month</option>
                  {MONTHS.map(([value, name], idx) => <option key={idx} value={value}>{name}</option>)}
                </select>
                {this.hasErrorFor('from_month') &&
                  <span className="field-message field-message--error">{this.errorFor('from_month')}</span>}
              </div>
            </div>
          </div>
          <div className={`field field--fullWidth${this.hasErrorFor('to_month') || this.hasErrorFor('to_year') ? ' is-erred' : ''}`}>
            <label className="field-label" htmlFor={this.idFor('to-year')}>End Date</label>
            <div className="bucket bucket--spacing bucket--start">
              <div className="field-select field-select--fullWidth">
                <select defaultValue={this.state.to_month} id={this.idFor('to-month')} name={this.nameFor('to_month')}>
                  <option value="">Month</option>
                  {MONTHS.map(([value, name], idx) => <option key={idx} value={value}>{name}</option>)}
                </select>
                {this.hasErrorFor('to_month') &&
                  <span className="field-message field-message--error">{this.errorFor('to_month')}</span>}
              </div>
              <div className="field-select field-select--fullWidth">
                <select defaultValue={this.state.to_year} id={this.idFor('to-year')} name={this.nameFor('to_year')}>
                  <option value="">Year</option>
                  {YEARS.map((value, idx) => <option key={idx}>{value}</option>)}
                </select>
                {this.hasErrorFor('to_year') &&
                  <span className="field-message field-message--error">{this.errorFor('to_year')}</span>}
              </div>
            </div>
          </div>
        </div>
        <div className={`field${this.hasErrorFor('details') ? ' is-erred' : ''}`}>
          <label className="field-label" htmlFor={this.idFor('details')}>Details</label>
          <textarea
            className="field-textarea"
            defaultValue={this.state.details}
            id={this.idFor('details')}
            name={this.nameFor('details')}
            maxLength="140" />
          <span className="field-message">Max. 140 characters</span>
          {this.hasErrorFor('details') &&
            <span className="field-message field-message--error">{this.errorFor('details')}</span>}
        </div>
        <div className="profile-work-remove">
          <a href="#" className="ts--down1" onClick={this.props.remove}>Remove this Experience</a>
        </div>
      </div>
    );
  }
}

export default WorkExperience;
