import React from 'react';
import ReactDOM from 'react-dom';

import { Links } from 'src/components';

const domContainers = document.querySelectorAll('.links-builder');

Array.prototype.forEach.call(domContainers, container => {
  const dataset = container.dataset;
  const errors = JSON.parse(dataset.errors);
  const urls = JSON.parse(dataset.urls);
  ReactDOM.render(<Links errors={errors} prefix={dataset.prefix} urls={urls} />, container);
});
