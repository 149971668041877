import React, { Fragment, useState, useEffect, memo, useCallback } from 'react';
import { classNames, Icon, DetailedCheckBoxField, } from 'src/components';

const ADD = 'add';
const REMOVE = 'remove';

const Contest = memo(({
  id,
  name,
  link,
  limitReached,
  isChecked,
  isDisabled,
  onSelected,
}) => {
  const labelFor = `entry_contest_ids_${id}`;
  const displayAsDisabled = limitReached || isDisabled;

  const onChange = (e) => {
    onSelected(e.target.checked ? ADD : REMOVE, id);
  };

  const errorMessage = limitReached ? 'You have already submitted an entry to this category.' : null;

  return (
    <div className="grid-box grid-box--S--6 grid-box--L--4">
      <DetailedCheckBoxField
        name="entry[contest_ids][]"
        label={name}
        disabled={displayAsDisabled}
        checked={isChecked}
        value={id}
        onChange={onChange}
        errorMessage={errorMessage}
        link={link}
        linkText={`Details`}
      />
    </div>
  );
});

const ContestGroup = memo(({ name, contests, maxContestsPerEntry, selectedContestIds, onSelected, }) => {
  const contestIds = contests.map((c) => +c.id);
  const isChecked = (compare) => selectedContestIds.indexOf(compare) >= 0;
  const totalSelected = selectedContestIds.filter((i) => contestIds.indexOf(i) >= 0).length;
  const maxHit = totalSelected >= maxContestsPerEntry;

  return (
    <Fragment>
      <h4 className="heading-5 mb--08 mt--2">
        {name} { maxHit && <span style={{ fontSize: 14, float: 'right' }} className="field-message--error">You've reached the maximum selections</span> }
      </h4>
      <div className="grid grid--flush grid--S">
        {
          contests.map((contest) =>
            <Contest
              key={`contest-${contest.id}`}
              isChecked={isChecked(contest.id)}
              isDisabled={(maxHit && !isChecked(contest.id))}
              onSelected={onSelected}
              {...contest}
            />
          )
        }
      </div>
    </Fragment>
  );
});

const ContestGroups = ({ selected, groups }) => {
  const [selectedContestIds, setSelectedContestIds] = useState([]);

  const onSelected = useCallback((action, id) => {
    setSelectedContestIds((data) => {
      if (action === ADD) {
        return [...data, id];
      } else if (action === REMOVE) {
        let newArr = [...data];
        newArr.splice(data.indexOf(id), 1);

        return newArr; // remve
      } else {
        return data;
      }
    });
  }, []);

  useEffect(() => {
    setSelectedContestIds(selected);
  }, [selected.length]);

  return (
    <Fragment>
      {
        groups.map((group) =>
          <ContestGroup
            key={`contest-group-${group.id}`}
            onSelected={onSelected}
            selectedContestIds={selectedContestIds}
            {...group}
          />
        )
      }
    </Fragment>
  )
}

export default ContestGroups;
