import React, { memo, } from 'react';
import PropTypes from 'prop-types';
import Assets from 'rails_assets';

const Icon = memo(({ symbol, size, }) => {
  return (
    <svg className={`icon icon--${size}`}>
      <use xlinkHref={`${Assets['icons.svg']}#${symbol}`}></use>
    </svg>
  );
});

Icon.displayName = 'IconComponent';

Icon.propTypes = {
  size: PropTypes.string.isRequired,
  symbol: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  size: 'M'
};

export default Icon;
