import 'whatwg-fetch';
import React, { useEffect, useState, } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import Assets from 'rails_assets';

const ActivityStream = ({ any, token, }) => {
  const [isHovered, setHovered] = useState(false);
  const isActive = any === 'true';
  const spanClassNames = classNames({
    'navigation-events': true,
    'is-active': isActive
  });

  useEffect(() => {
    if (isHovered) {
      fetch('/notifications/viewed', { credentials: 'same-origin' });
    }
  }, [isHovered]);

  return (
    <a
      className={spanClassNames}
      onMouseOver={() => setHovered(true)}
      href="/notifications"
    >
      <svg className="icon" role="img" title="Notification">
        <use
          href={`${Assets['icons.svg']}#notification${isActive ? '-new' : ''}`}
          xlinkHref={`${Assets['icons.svg']}#notification${isActive ? '-new' : ''}`}
        ></use>
      </svg>
    </a>
  );
};

const containers = document.querySelectorAll('.events-toggle');

Array.prototype.forEach.call(containers, container => {
  ReactDOM.render(<ActivityStream {...(container.dataset)} />, container);
});
