// -------------------------------------
// Image Lazy Loazing
// -------------------------------------
export default function($parent) {
  const onImageLoad = (event) => {
    event.currentTarget.classList.add("loaded");
    event.currentTarget.classList.remove("waiting");
    event.currentTarget.removeEventListener("load", onImageLoad);
  };

  /* Use observer to lazy load images */
  const visibleObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // When the image enters the viewport, load the image
        const target = entry.target;

        if (!target.srcset && target.dataset.srcset) {
          target.srcset = target.dataset.srcset;
        }

        if (!target.src && target.dataset.src) {
          target.src = target.dataset.src;
        }

        // Stop observing the image once it's loaded
        observer.unobserve(target);
      }
    });
  });

  const nodes = $parent.querySelectorAll("img[data-src], img[data-srcset]");
  const images = Array.from(nodes);

  [...new Set(images)].forEach(($ele) => {
		if (
			$ele.classList.contains("loaded") ||
			$ele.classList.contains("waiting")
		) {
			return;
		}

		$ele.classList.add("waiting");
    $ele.addEventListener("load", onImageLoad);

		visibleObserver.observe($ele);
	});
};
