import React, { Fragment } from 'react';
import {
  SortableContainer,
  SortableElement,
  arrayMove
} from 'react-sortable-hoc';

import { Icon, Button } from 'src/components';

import {
  VimeoEmbed,
  YoutubeEmbed,
  SketchfabEmbed,
  GistEmbed,
  Image,
  ImageRow,
  ImageProcess,
  Spacer,
  Text,
  Empty,
  Reorder,
  MarmosetViewer,
} from './builder';

const MEDIA_TYPES = {
  [Text.TYPE]: { component: Text, icon: "text", label: "Add Text", contentClass: "" },
  [Image.TYPE]: { component: Image, icon: "image", label: "Add Images", contentClass: "is-filled" },
  [ImageRow.TYPE]: { component: ImageRow, icon: "image-row", label: "Add Image Row", contentClass: "is-filled" },
  [ImageProcess.TYPE]: { component: ImageProcess, icon: "process-view", label: "Add Process View", contentClass: "is-filled" },
  [VimeoEmbed.TYPE]: { component: VimeoEmbed, icon: "social-vimeo", label: "Embed Vimeo", contentClass: "is-filled" },
  [YoutubeEmbed.TYPE]: { component: YoutubeEmbed, icon: "social-youtube", label: "Embed YouTube", contentClass: "is-filled" },
  [SketchfabEmbed.TYPE]: { component: SketchfabEmbed, icon: "social-sketchfab", label: "Add 3D Viewer", contentClass: "is-filled" },
  [MarmosetViewer.TYPE]: { component: MarmosetViewer, icon: "marmoset", label: "Add Marmoset File", contentClass: "is-filled" },
  [GistEmbed.TYPE]: { component: GistEmbed, icon: "social-github", label: "Add Code Snippet", contentClass: "is-filled" },
  [Spacer.TYPE]: { component: Spacer, icon: "spacer", label: "Add Spacer", contentClass: "" },
}

class MediaBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editingIndex: null, media: this.props.media, reorder: false, sidebarCollapsed: false };
    this.getMediumComponent = this.getMediumComponent.bind(this);
    this.getMediumComponentToReorder = this.getMediumComponentToReorder.bind(this);
  }

  addMedia(media) {
    this.setState(state => {
      state.media.push(media);
      state.editingIndex = state.media.length - 1;
      return state;
    });

    this.contentBottom.scrollIntoView({ behavior: "smooth" });
  }

  getMediumComponent(medium, index, total) {
    const done = () => this.setState({editingIndex: null});

    const onChange = (value) => this.setState(state => {
      const medium = state.media[index];
      MEDIA_TYPES[medium.type].component.update(medium, value);

      this.props.onChange(state);
      return state;
    });

    const toggleReorder = () => this.setState({reorder: !this.state.reorder});

    const remove = () => this.setState(state => {
      state.media.splice(index, 1);
      state.editingIndex = null;
      this.props.onChange(state);
      return state;
    });

    const removeItem = (itemIndex) => this.setState(state => {
      const medium = state.media[index];
      medium.urls.splice(itemIndex, 1);

      this.props.onChange(state);
      return state;
    });

    const sortItem = ({newIndex, oldIndex}) => this.setState(state => {
      const medium = state.media[index];
      medium.urls = arrayMove(medium.urls, oldIndex, newIndex);

      this.props.onChange(state);
      return state;
    });

    const Component = MEDIA_TYPES[medium.type].component;
    const isEditing = this.state.editingIndex === index;
    const canEdit = this.state.editingIndex === null;
    const refFn = (total - 1) == index ? (el) => { this.contentBottom = el; } : null

    return (
      <div ref={refFn} key={index} className={`builder-card ${isEditing ? 'is-editing' : MEDIA_TYPES[medium.type].contentClass}`}>
        <div className="builder-card-actions">
          {!isEditing && canEdit && (
            <Fragment>
              <Button
                onClick={() => this.setState({editingIndex: index})}
                symbol="edit"
                tooltip="Edit"
              />
              <Button
                onClick={toggleReorder}
                symbol="reorder"
                tooltip="Reorder"
              />
            </Fragment>
          )}
          {isEditing && (
            <Button
              onClick={remove}
              symbol="remove"
              tooltip="Remove"
            />
          )}
        </div>
        <Component done={done} isEditing={isEditing} onChange={onChange} removeItem={removeItem} sortItem={sortItem} entry={this.props.entry} username={this.props.username} {...medium} />
      </div>
    );
  }

  getMediumComponentToReorder(medium, index) {
    const remove = () => this.setState(state => {
      state.media.splice(index, 1);
      this.props.onChange(state);
      if (state.media.length === 0) {
        this.setState({reorder: !this.state.reorder})
      }
      return state;
    });

    return <Reorder key={`medium-component-${index}`} remove={remove} variant={medium.type} {...medium} />
  }

  onSortEnd({newIndex, oldIndex}) {
    this.setState(state => {
      state.media = arrayMove(state.media, oldIndex, newIndex);
      this.props.onChange(state);
      return state;
    });
  }

  render() {
    const totalItems = this.state.media ? this.state.media.length : 0;
    const items = this.state.media.map((medium, index) =>
      this.getMediumComponent(medium, index, totalItems)
    );

    const SortableItem = SortableElement(({ idx, medium }) =>
      this.getMediumComponentToReorder(medium, idx)
    );

    const SortableList = SortableContainer(({ media }) => {
      return (
        <div className="builder-reorder">
          {media.map((medium, index) => (
            <SortableItem idx={index} index={index} key={index} medium={medium} />
          ))}
        </div>
      );
    });

    const refFn = totalItems === 0 ? (el) => { this.contentBottom = el; } : null

    return (
      <Fragment>
        <div className="builder-content" ref={refFn}>
          {this.state.media.length ?
            this.state.reorder ?
              <div className="builder-card is-editing">
                <label className="builder-card-heading">Drag and drop to reorder content</label>
                <div className="builder-card-actions">
                  <button
                    className="button button--secondary"
                    onClick={() => this.setState({reorder: !this.state.reorder})}
                    type="button"
                  >
                    Done
                  </button>
                </div>
                <SortableList media={this.state.media} onSortEnd={this.onSortEnd.bind(this)}  />
              </div>
            :
              items
          :
            <Empty wip={this.props.wip} />
          }

        </div>
        <div className="builder-sidebar">
          <button
            className={`builder-sidebar-collapse ${this.state.sidebarCollapsed ? 'is-collapsed' : ''}`}
            onClick={() => this.setState({
              sidebarCollapsed: !this.state.sidebarCollapsed
            })}
            type="button"
          >
            <Icon symbol="collapse" />
            <span>Collapse Sidebar</span>
          </button>
          <div className={`navigationSide ${this.state.sidebarCollapsed ? 'is-collapsed' : ''}`}>
            <ul className="navigationSide-list">
              {Object.keys(MEDIA_TYPES).map((type) => (
                <li key={type}>
                  <button
                    className={`navigationSide-link ${(this.state.reorder || this.state.editingIndex !== null) && 'is-disabled'}`}
                    onClick={() => !this.state.reorder && this.state.editingIndex === null && this.addMedia(MEDIA_TYPES[type].component.initial())}
                    type="button"
                  >
                    <Icon symbol={MEDIA_TYPES[type].icon} />
                    <span className="navigationSide-hideable">{MEDIA_TYPES[type].label}</span>
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default MediaBuilder;
