import React from 'react';

import { ImagesEdit, Icon, DropUploader, ImageError } from 'src/components';
import Narrow from './narrow';
import { builderProcess, } from 'src/utils';

class ImageProcess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      error: null,
      limit: 5,
      narrow: props.narrow
    };

    this.doChange = this.doChange.bind(this);
    this.toggleNarrow = this.toggleNarrow.bind(this);
  }

  addSliderListener() {
    if (this.eventedElement) {
      this.eventedElement.removeEventListener('input', this.eventCallback);
      this.eventedElement = null;
    }

    if (this.builderProcessElement) {
      [this.eventedElement, this.eventCallback] = builderProcess(this.builderProcessElement);
    }
  }

  componentDidMount() {
    this.addSliderListener();
  }

  componentDidUpdate() {
    this.addSliderListener();
  }

  doChange(files) {
    this.props.onChange({files: files});
  }

  toggleNarrow() {
    const newState = !this.state.narrow;
    this.setState(
      {narrow: newState},
      () => this.props.onChange({narrow: newState})
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isEditing &&
          <React.Fragment>
          <span className="heading-6 mb--0">Process View</span>
            <span className="builder-card-heading">Add up to {this.state.limit} images, then use slider to fade between them. <em>First image determines aspect&nbsp;ratio</em>.</span>
            <ImagesEdit onSortEnd={this.props.sortItem} removeItem={this.props.removeItem} urls={this.props.urls} />
          </React.Fragment>
        }

        {!this.props.isEditing &&
          <Narrow narrow={this.state.narrow} toggleNarrow={this.toggleNarrow} />
        }

        {this.state.error &&
          <ImageError message={this.state.error.message} />
        }

        {!this.props.isEditing && this.props.urls.length > 0 &&
          <div
            className={`builder-process${this.state.narrow ? ' builder-narrow' : ''}`}
            ref={(el) => { this.builderProcessElement = el; }}
          >
            {this.props.urls.map((url, index) =>
                <div className="builder-process-image" key={index}>
                  <img src={url} draggable="false" />
                </div>
            )}
            <div className="builder-process-indicator">
              <Icon symbol='process-view' title='Process View' />
            </div>
            <div className="builder-process-range">
              <input type="range" defaultValue="0" className="builder-process-range-input" />
            </div>
          </div>
        }

        {!this.props.isEditing && this.props.urls.length === 0 &&
          <ImageError message='Missing images' />
        }

        {this.props.isEditing && this.props.urls.length < this.state.limit &&
          <DropUploader
            label='Add Images'
            limit={this.state.limit - this.props.urls.length}
            numFiles={this.props.urls.length}
            onChange={this.doChange}
            onError={this.onError}
            onUploadStart={() => {this.setState({error: null});}}
            suffix={this.props.username}
          />
        }

        {this.props.isEditing && this.props.urls.length !== 0 &&
          <div className="field-actions">
            <button
              className="button button--minWidth button--secondary"
              onClick={this.props.done}
            >Done</button>
          </div>
        }
      </React.Fragment>
    );
  }
};


ImageProcess.TYPE = 'image-process';
ImageProcess.initial = () => ({urls: [], narrow: true, type: ImageProcess.TYPE});
ImageProcess.update = (medium, { files, narrow }) => {
  if (files) {
    files.map(f => medium.urls.push(f.url))
  }

  if (typeof(narrow) !== 'undefined') {
    medium.narrow = narrow;
  }
}

export default ImageProcess;
