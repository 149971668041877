import React from 'react';

const ImageError = ({ message }) => (
  <div className="field-message field-message--error mt--0">
    <h3 className="mb--0 mt--0">An Error Has Occurred</h3>
    <p className="mb--04 mt--0">Error: {message}</p>
  </div>
);

export default ImageError;
