import React, { Fragment } from 'react';
import Narrow from './narrow';

class SketchfabEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      link: props.link,
      narrow: props.narrow
    }

    this.update = this.update.bind(this);
    this.toggleNarrow = this.toggleNarrow.bind(this);
  }

  extract_id(url) {
    try {
      return url.match(/https:\/\/[www\.]?sketchfab.com\/(3d\-)?models\/([a-z0-9\-\_]*)([a-z0-9]{32})[\/.*|\?.*]?/).pop();
    } catch(error) {
      return "";
    }
  }

  update(e) {
    this.setState({ link: this.extract_id(e.target.value) }, () => {
      this.props.onChange(this.state);
    })
  }

  value() {
    if (this.state.link) {
      return `https://sketchfab.com/models/${this.state.link}`;
    } else {
      return "";
    }
  }

  toggleNarrow() {
    const newState = !this.state.narrow;
    this.setState(
      {narrow: newState},
      () => this.props.onChange(this.state)
    );
  }

  onKeyDown(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      this.props.done();
    }
  }

  render() {
    return (
        <Fragment>
          {this.props.isEditing ?
            <Fragment>
              <span className="heading-6 mb--0">Sketchfab Viewer</span>
              <span className="builder-card-heading">Add a Sketchfab link to embed your 3D files and animations.</span>
              <div className="field">
                <input
                  className="field-input"
                  onKeyDown={this.onKeyDown.bind(this)}
                  onChange={(e) => this.update(e) }
                  placeholder="https://sketchfab.com/models/442c548d94744641ba279ae94b5f45ec"
                  value={this.value()}
                />
              </div>
              {this.props.content !== '' &&
                <div className="field-actions">
                  <button
                    className="button button--minWidth button--secondary"
                    onClick={this.props.done}
                  >Done</button>
                </div>
              }
            </Fragment>
            :
            <Fragment>
              <Narrow narrow={this.state.narrow} toggleNarrow={this.toggleNarrow} />
              <div className={`builder-embed${this.state.narrow ? ' builder-narrow' : ''}`}>
                <iframe src={"https://sketchfab.com/models/" + this.state.link + "/embed"} />
              </div>
            </Fragment>
          }
        </Fragment>
    );
  }
};

SketchfabEmbed.TYPE = 'sketchfab-embed';
SketchfabEmbed.initial = () => ({link: '', narrow: true, type: SketchfabEmbed.TYPE});
SketchfabEmbed.update = (medium, { link, narrow }) => {
  medium.link = link;

  if (typeof(narrow) !== 'undefined') {
    medium.narrow = narrow;
  }
}

export default SketchfabEmbed;
