import React from 'react';
import ReactDOM from 'react-dom';

import WorkExperiences from 'src/work-experiences';

const domContainers = document.querySelectorAll('.work-experiences-builder');

Array.prototype.forEach.call(domContainers, container => {
  const dataset = container.dataset;
  const errors = JSON.parse(dataset.errors);
  const workExperiences = JSON.parse(dataset.work_experiences);

  ReactDOM.render(
    <WorkExperiences
      errors={errors}
      prefix={dataset.prefix}
      workExperiences={workExperiences}
    />,
    container
  );
});
