export { default as builderProcess } from './builder_process';
export { default as builderRow } from './builder_row';
export { default as initPhotoSwipe } from './init_photo_swipe';
export { default as countdown } from './countdown';

export const onDomReady = (callback) => {
  return document.addEventListener('DOMContentLoaded', callback);
}

export const csrfToken = () => document.head.querySelector('meta[name="csrf-token"]').content;

export { default as lazyLoadImages } from './lazy_load_images';
