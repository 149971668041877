import React, { Fragment } from 'react';
import Narrow from './narrow';

class YoutubeEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      link: props.link,
      narrow: props.narrow,
      originalUrl: '',
      error: '',
      validMessage: ''
    };

    this.toggleNarrow = this.toggleNarrow.bind(this);
  }

  validateUrl(url) {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);
    return match ? match[1] : false;
  }

  checkVideoAvailability(videoId) {
    const oEmbedUrl = `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`;

    fetch(oEmbedUrl)
      .then(response => {
        if (!response.ok) {
          throw new Error('Video is unavailable');
        }
        return response.json();
      })
      .then(data => {
        this.setState({ videoAvailable: true, error: '', validMessage: 'This is a valid Youtube video.' });
      })
      .catch(error => {
        this.setState({ videoAvailable: false, error: 'This video is not available on Youtube.', validMessage: '' });
      });
  }

  update(e) {
    const url = e.target.value;
    const videoId = this.validateUrl(url);

    if (videoId) {
      this.setState({ link: videoId, originalUrl: url }, () => {
        this.checkVideoAvailability(videoId);
        this.props.onChange(this.state); // Ensure the onChange is called with the updated state
      });
    } else {
      this.setState({ originalUrl: url, error: 'This is not a valid Youtube url' });
    }
  }

  value() {
    if (this.state.error) {
      return this.state.originalUrl || this.state.link;  // Show the original URL on error
    }
    return this.state.link ? `https://youtu.be/${this.state.link}` : '';
  }

  extract_id(url) {
    try {
      if (!!url.match(/^https?:\/\/(.+\.)?youtu\.be/)) {
        return url.match(/\.be\/([a-zA-Z0-9\-\_]+)\?*.*/).pop();
      } else if (!!url.match(/\?v=([^&]+)/)) {
        return url.match(/\?v=([^&]+)/).pop();
      } else {
        return url;
      }
    } catch(error) {
      return "";
    }
  }

  toggleNarrow() {
    const newState = !this.state.narrow;
    this.setState(
      {narrow: newState},
      () => this.props.onChange(this.state)
    );
  }

  onKeyDown(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      this.props.done();
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.isEditing ?
          <Fragment>
            <span className="heading-6 mb--0">Embed YouTube</span>
            <span className="builder-card-heading">Add a YouTube url to embed video.</span>
            <div className="field">
              <input
                className={`field-input ${this.state.error ? 'input-error' : ''}`}
                onKeyDown={this.onKeyDown.bind(this)}
                onChange={(e) => this.update(e)}
                placeholder="e.g. https://youtu.be/1AB-CD2Efgh"
                value={this.value()}  // Ensure this shows the shortened URL
                autoFocus={true}
              />
              {this.state.error && (
                <div className="error-message" style={{ color: 'var(--orange)', marginTop: '5px' }}>
                  {this.state.error}
                </div>
              )}
              {!this.state.error && this.state.validMessage && (
                <div className="valid-message" style={{ color: 'var(--green)', marginTop: '5px' }}>
                  {this.state.validMessage}
                </div>
              )}
            </div>
            {this.props.content !== '' &&
            <div className="field-actions">
              <button
                className="button button--minWidth button--secondary"
                onClick={this.props.done}
                disabled={!!this.state.error}  // Disable the button if there's an error
              >Done</button>
            </div>
            }
          </Fragment>
          :
          <Fragment>
            <Narrow narrow={this.state.narrow} toggleNarrow={this.toggleNarrow} />
            <div className={`builder-embed${this.state.narrow ? ' builder-narrow' : ''}`}>
              <iframe src={`https://www.youtube.com/embed/${this.state.link}`} />
            </div>
          </Fragment>
        }
      </Fragment>
    );
  }
};

YoutubeEmbed.TYPE = 'youtube-embed';
YoutubeEmbed.initial = () => ({ link: '', narrow: true, type: YoutubeEmbed.TYPE });
YoutubeEmbed.update = (medium, { link, narrow }) => {
  medium.link = link;

  if (typeof(narrow) !== 'undefined') {
    medium.narrow = narrow;
  }
}

export default YoutubeEmbed;
