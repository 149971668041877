import React from 'react';
import Url from 'url';

import { Icon } from 'src/components';

const PLATFORM_MAPPING = {
  facebook: 'social-facebook',
  github: 'social-github',
  instagram: 'social-instagram',
  linkedin: 'social-linkedin',
  personal: 'on-the-web',
  sketchfab: 'social-sketchfab',
  steam: 'social-steam',
  tumblr: 'social-tumblr',
  twitch: 'social-twitch',
  twitter: 'social-twitter',
  vimeo: 'social-vimeo',
  youtube: 'social-youtube'
}

export function determinePlatform(url) {
  let platform = 'personal';

  const domain = extractDomain(url);

  switch(domain) {
    case 'facebook.com':
      platform = 'facebook';
      break;
    case 'github.com':
      platform = 'github';
      break;
    case 'instagram.com':
      platform = 'instagram';
      break;
    case 'linkedin.com':
      platform = 'linkedin';
      break;
    case 'sketchfab.com':
      platform = 'sketchfab';
      break;
    case 'steamcommunity.com':
      platform = 'steam';
      break;
    case 'tumblr.com':
      platform = 'tumblr';
      break;
    case 'twitch.tv':
      platform = 'twitch';
      break;
    case 'twitter.com':
      platform = 'twitter';
      break;
    case 'vimeo.com':
      platform = 'vimeo';
      break;
    case 'youtube.com':
      platform = 'youtube';
      break;
  }

  return platform;
}

function extractDomain(url) {
  try {
    // extract youtube.com of www.youtube.com/path
    const parsed = Url.parse(url);
    const hostname = parsed.hostname || extractHostname(url);
    const domainParts = hostname.split('.');
    domainParts.reverse().splice(2);
    return domainParts.reverse().join('.');
  }
  catch (e) {
    return;
  }
}

function extractHostname(url) {
  return url.split('/')[0];
}

class Links extends React.Component {
  constructor(props) {
    super(props);
    this.state = { urls: props.urls };
  }

  addUrl() {
    this.setState(state => state.urls.push({platform: 'personal', url: ''}));
  }

  fetchError(index) {
    const data = this.props.errors || {};
    const errors = data[`urls.${index}.url`];
    return (errors || []).join(', ');
  }

  onChange(event, index) {
    const url = event.target.value;
    const platform = determinePlatform(url);

    this.setState(state => {
      state.urls[index].platform = platform;
      state.urls[index].url = url;
      return state;
    });
  }

  remove(index) {
    this.setState(state => {
      state.urls.splice(index, 1);
      return state;
    });
  }

  render() {
    let error;

    return (
      <React.Fragment>
        {this.state.urls.map((url, index) => {
          error = this.fetchError(index);
          return (
            <div className="bucket bucket--spacing mb--12" key={index}>
              <div>
                <Icon symbol={PLATFORM_MAPPING[url.platform]} />
              </div>
              <div className="profile-web-url">
                <div className={`field${error && ' is-erred'} mb--0`}>
                  <input value={url.platform} name={`${this.props.prefix}[urls][][platform]`} type="hidden" />
                  <input className="field-input" value={url.url} name={`${this.props.prefix}[urls][][url]`} onChange={e => this.onChange(e, index)} type="text" placeholder="URL" />
                  {error &&
                    <span className="field-message field-message--error">{error}</span>}
                </div>
              </div>
              <div>
                <button className="buttonCircle buttonCircle--S buttonCircle--secondary" onClick={e => this.remove(index)} type="button">
                  <Icon size='S' symbol="remove" />
                </button>
              </div>
            </div>
          );
        })}
        <a className="profile-web-new" href="javascript:" onClick={e => this.addUrl()}>+ Add Link</a>
      </React.Fragment>
    );
  }
}

export default Links;
