import React from 'react';
import { Icon } from 'src/components';

const Button = ({
  disabled = false,
  type,
  onClick,
  symbol,
  tooltip,
  children,
  className = 'buttonCircle buttonCircle--S buttonCircle--secondary buttonCircle--shadow'
}) => {
  return (
    <button
      className={className}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {symbol && <Icon size='S' symbol={symbol} />} {children}
      {tooltip && <span className="buttonCircle-tooltip">{tooltip}</span>}
    </button>
  );
}


export default Button;
