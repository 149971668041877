import React, { Fragment } from 'react';

import { ImagesEdit, DropUploader, ImageError } from 'src/components';

import Narrow from './narrow';
import { builderRow } from 'src/utils';

class ImageRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      limit: 4,
      narrow: props.narrow
    };

    this.doChange = this.doChange.bind(this);
    this.toggleNarrow = this.toggleNarrow.bind(this);
    this.onUploadStart = this.onUploadStart.bind(this);
  }

  componentDidUpdate() {
    if (this.props.urls.length !== 0) {
      builderRow(this.builderRowElement);
    }
  }

  toggleNarrow() {
    const newState = !this.state.narrow;
    this.setState(
      {narrow: newState},
      () => this.props.onChange({narrow: newState})
    );
  }

  doChange(files) {
    this.props.onChange({files: files});
  }

  onUploadStart() {
    this.setState({error: null});
  }

  render() {
    return (
      <Fragment>
        {this.props.isEditing &&
          <Fragment>
            <span className="heading-6 mb--0">Image Row</span>
            <span className="builder-card-heading">Add up to {this.state.limit} images to create a row with equal image heights.</span>
            <ImagesEdit onSortEnd={this.props.sortItem} removeItem={this.props.removeItem} urls={this.props.urls} />
          </Fragment>
        }
        {!this.props.isEditing &&
          <Narrow narrow={this.state.narrow} toggleNarrow={this.toggleNarrow} />
        }

        {this.state.error &&
          <ImageError message={this.state.error.message} />
        }

        {this.props.isEditing && this.props.urls.length < this.state.limit &&
          <DropUploader
            label='Add Images'
            limit={this.state.limit - this.props.urls.length}
            numFiles={this.props.urls.length}
            onChange={this.doChange}
            onError={(error) => {this.setState({error});}}
            onUploadStart={this.onUploadStart}
            suffix={this.props.username}
          />
        }

        {this.props.isEditing && this.props.urls.length !== 0 &&
          <div className="field-actions">
            <button
              className="button button--minWidth button--secondary"
              onClick={this.props.done}
            >Done</button>
          </div>
        }

        <div
          className={`builder-row${this.state.narrow ? ' builder-narrow' : ''} ${this.props.isEditing ? 'is-hidden' : ''}`}
          ref={(el) => { this.builderRowElement = el; }}
        >
          {this.props.urls.map((url, index) =>
            <div key={index}>
              <img src={url} />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
};


ImageRow.TYPE = 'image-row';
ImageRow.initial = () => ({urls: [], narrow: true, type: ImageRow.TYPE});
ImageRow.update = (medium, { files, narrow }) => {
  if (files) {
    files.map(f => medium.urls.push(f.url))
  }

  if (typeof(narrow) !== 'undefined') {
    medium.narrow = narrow;
  }
}


export default ImageRow;
