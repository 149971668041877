import React from 'react';
import ReactDOM from 'react-dom';
import { lazyLoadImages, initPhotoSwipe, builderProcess, builderRow, countdown } from 'src/utils';
import { HighFiveButton } from 'src/components';
import Recaptcha from 'src/utils/recaptcha';

document.addEventListener('DOMContentLoaded', () => {
  // ----- Countdown ----- //
  const contestCountdown = document.getElementById('contest-countdown-days');
  if (contestCountdown) {
    countdown(contestCountdown.getAttribute('data-countdown'));
  }

  const forms = document.querySelectorAll('form.g-protected');
  Array.prototype.forEach.call(forms, form => {
    Recaptcha(form);
  });
  // ----- Process ----- //

  const allBuilderProcess = document.getElementsByClassName('builder-process');
  Array.prototype.forEach.call(allBuilderProcess, el => builderProcess(el));

  // ----- Row ----- //

  const allBuilderRow = document.getElementsByClassName('builder-row');
  Array.prototype.forEach.call(allBuilderRow, el => builderRow(el));

  // -------------------------------------
  //   Navigation
  // -------------------------------------

  // ----- Narrow: Toggle ----- //

  const navigation = document.getElementById('navigation');
  const navigationToggle = document.getElementById('navigation-toggle');

  if (navigation) {
    navigationToggle.addEventListener("click", function(event) {
      event.preventDefault();
      navigation.classList.toggle('is-active');
    });
  }

  // -------------------------------------
  //   Onboarding
  // -------------------------------------

  // ----- Step 1: Modal ----- //

  const closeProModal = (e) => {
    modalPro.classList.remove('is-active');
    openPro.checked = false;
  };
  const openProModal = (e) => {
    modalPro.classList.add('is-active');
  };

  const modalPro = document.getElementById('level-modal');
  const closePro = document.getElementById('level-close');
  const openPro = document.getElementById('level-pro');

  if (modalPro) {
    closePro.addEventListener('click', closeProModal);
    openPro.addEventListener('click', openProModal);
  }

  // ----------------------------------------
  //   Onboarding Step 5 and Profile Settings
  // ----------------------------------------

  const onboardThemeLight = document.getElementById('theme-light');
  const onboardThemeDark = document.getElementById('theme-dark');

  if (onboardThemeLight) {
    onboardThemeLight.addEventListener("click", function() {
      document.documentElement.className = 'theme-light';
    });
  }
  if (onboardThemeDark) {
    onboardThemeDark.addEventListener("click", function() {
      document.documentElement.className = 'theme-dark';
    });
  }

  // -------------------------------------
  //   Style Guide
  // -------------------------------------

  // ----- Theme: Toggle ----- //

  const themeSwitcher = document.querySelector(".styleGuide-theme");

  if (themeSwitcher) {
    themeSwitcher.addEventListener("click", function(event) {
      event.preventDefault();
      var root = document.documentElement.className;
      if (root === 'theme-dark' || root === '') {
        document.documentElement.className = 'theme-light';
      } else {
        document.documentElement.className = 'theme-dark';
      }
    });
  }

  // -------------------------------------
  //   Show / Hide related fields
  // -------------------------------------

  const hideTargets = document.querySelectorAll('[data-hide-target]');
  Array.prototype.forEach.call(hideTargets, el => {
    const targets = document.querySelectorAll(el.dataset.hideTarget);
    const hide = () => Array.prototype.forEach.call(targets, e => e.hidden = true);
    const check = () => { if (el.checked) { hide(); } };
    el.addEventListener('change', check);
    check();
  });

  const showTargets = document.querySelectorAll('[data-show-target]');
  Array.prototype.forEach.call(showTargets, el => {
    const targets = document.querySelectorAll(el.dataset.showTarget);
    const show = () => Array.prototype.forEach.call(targets, e => e.hidden = false);
    const check = () => { if (el.checked) { show(); } };
    el.addEventListener('change', check);
    check();
  });

  // -------------------------------------
  //   Field Select Filter
  // -------------------------------------

  const filterForms = document.querySelectorAll('.field-select--filter');
  Array.prototype.forEach.call(filterForms, form => {
    const selectBoxes = form.querySelectorAll('select');

    Array.prototype.forEach.call(selectBoxes, select => {
      select.addEventListener('change', () => form.submit());
    });
  });

  //

  const highFiveForms = document.querySelectorAll('div.high-five-button');
  Array.prototype.forEach.call(highFiveForms, form => {
    const type = form.getAttribute('data-type');
    const id = form.getAttribute('data-id');
    const selected = form.getAttribute('data-selected') === 'true';
    const modelId = form.getAttribute('data-model-id');

    ReactDOM.render(
      <HighFiveButton
        type={type}
        id={id}
        selected={selected}
        modelId={modelId}
      />,
      form
    );
  });

  initPhotoSwipe();

  lazyLoadImages(document.body);
});
