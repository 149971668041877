import 'promise-polyfill/src/polyfill';
import React, { Fragment } from 'react';
import upload from 'src/helpers/upload';

const MAX_MB_UPLOAD = 5;

export default class DropUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragOver: false,
      isUploading: false,
      numFiles: this.props.numFiles,
      extensionList: this.props.extensionList || 'JPG and PNG',
      verifyExtensionFn: this.props.verifyExtensionFn || ((file) => file.type.match('^image/') && !file.type.match('gif')),
      maxMbUpload: this.props.maxMbUpload || MAX_MB_UPLOAD
    };

    this.acceptFiles = this.acceptFiles.bind(this);
    this.onError = this.onError.bind(this);
    this.dragLeaveHandler = this.dragLeaveHandler.bind(this);
    this.dragOverHandler = this.dragOverHandler.bind(this);
    this.dropHandler = this.dropHandler.bind(this);
  }

  acceptFiles(files) {
    if (!files || !files.length) { return; }

    if (files.length > this.props.limit) {
      this.onError(new Error(`You've given too many files to upload. The limit is ${this.props.limit}.`));
      return;
    }

    if (!Array.prototype.every.call(files, this.state.verifyExtensionFn)) {
      this.onError(new Error(`Only ${this.state.extensionList} files may be uploaded.`));
      return;
    }

    if (!Array.prototype.every.call(files, file =>
      file.size <= (1024 * 1024 * this.state.maxMbUpload)
    )) {
      this.onError(new Error(`Uploads are limited being ${this.state.maxMbUpload}MB or less. Please reduce your file size and try again.`))
      return;
    }

    this.setState({ isUploading: true }, () => {
      this.props.onUploadStart();
      upload(files, this.props.suffix)
        .then((data) => this.setState({ isUploading: false }, () => this.props.onChange(data)))
        .catch(this.onError);
    });
  }

  dragLeaveHandler(e) {
    e.preventDefault();
    this.setState({dragOver: false});
  }

  dragOverHandler(e) {
    e.preventDefault();
    this.setState({dragOver: true});
  }

  dropHandler(e) {
    e.preventDefault();
    this.setState({dragOver: false});
    if (e.dataTransfer && e.dataTransfer.items.length > this.props.limit) {
      this.onError(new Error(`You've given too many files to upload. The limit is ${this.props.limit}.`));
      this.removeDragData(e);
      return;
    }

    if (!Array.prototype.every.call(e.dataTransfer.items, this.state.verifyExtensionFn)) {
      this.onError(new Error(`Only ${this.state.extensionList} files may be uploaded.`));
      this.removeDragData(e);
      return;
    }

    this.acceptFiles(e.dataTransfer.files);
    this.removeDragData(e);
  }

  onError(error) {
    this.setState({ isUploading: false });
    this.props.onError(error);
  }

  removeDragData(e) {
    if (e.dataTransfer.items) {
      e.dataTransfer.items.clear();
    } else {
      e.dataTransfer.clearData();
    }
  }

  render() {
    const { expanded, label, limit, small, } = this.props;
    const { dragOver, extensionList, maxMbUpload, isUploading, } = this.state;

    return (
      <Fragment>
        {limit !== 0 &&
          <div
            className={`upload ${dragOver ? 'is-dragOver' : ''} ${isUploading ? 'is-uploading' : ''} ${small ? 'upload--S' : ''} ${expanded ? 'upload--L' : ''}`}
            onDrop={this.dropHandler}
            onDragOver={this.dragOverHandler}
            onDragLeave={this.dragLeaveHandler}
          >
            <label>
              <div className={`has-icon ${small ? 'has-icon--S' : ''} upload-icon`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>upload</title>
                  <path id="icon-upload-arrow" d="M6.706 15.294h7.059V8.235h4.706L10.235 0 2 8.235h4.706z"/>
                  <path id="icon-upload-bar" d="M2 17.647h16.47V20H2z"/>
                </svg>
              </div>
              <strong>
                {isUploading ? 'Uploading...' : label}
              </strong>
              {
                isUploading ?
                <div className="upload-progress">
                  <div className="upload-progress-percentage"></div>
                </div>
              :
                <div>
                  <input
                    accept="image/jpeg, image/png, image/svg+xml"
                    multiple={limit > 1}
                    onChange={e => this.acceptFiles(e.target.files)}
                    type="file"
                  />
                  <div className="upload-message">
                    {extensionList} files less than {maxMbUpload}MB
                  </div>
                </div>
              }
            </label>
          </div>
        }
      </Fragment>
    );
  }
};
