const recaptchaSiteKey = document.querySelector('meta[name="rsk:app_id"]').content;

class Recaptcha {
  constructor({ api, key }) {
    this.api = api;
    this.key = key;
  }

  get isEnabled() {
    return this.api && this.key;
  }

  get isDisabled() {
    return !this.isEnabled;
  }

  execute(action) {
    if (this.isDisabled) return Promise.resolve();

    return new Promise((resolve, reject) => {
      this.api.ready(() => {
        try {
          this.api.execute(this.key, { action }).then(token => resolve(token));
        } catch (error) {
          reject(error);
        }
      });
    });
  }
}

const onReady = (form) => {
  const action = form.getAttribute('id');
  form.querySelector('button[type="submit"]').disabled = false;

  const recaptcha = new Recaptcha({
    api: window.grecaptcha,
    key: recaptchaSiteKey,
  });

  recaptcha.execute(action).then(token => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 'g-recaptcha-response';
    input.value = token;
    form.appendChild(input);
  });
}

export default (form) => {
  if (!form) return; // guard clause
  if (!document.querySelector('meta[name="rsk:app_id"]')) return; // guard clause

  form.querySelector('button[type="submit"]').disabled = true;

  if (!window.grecaptcha) {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
    script.onload = () => onReady(form);
    document.head.appendChild(script);
  } else {
    onReady(form);
  }
};
