import React, { Fragment, useState } from 'react';
import { classNames, DropUploader, ImageError, } from 'src/components';
import Narrow from './narrow';

const UPLOAD_LIMIT = 1;

const MarmosetViewer = ({
  done,
  isEditing,
  onChange,
  removeItem,
  sortItem,
  username,
  /* Object Params */
  type,
  narrow: isNarrow,
  url,
}) => {
  const [errorNotice, setError] = useState(null);

  const onUploadStart = () => {
    setError(null);
    // done();
  };

  const displayClassCss = classNames({
    'builder-image': true,
    'builder-narrow': isNarrow,
    'is-hidden': isEditing,
  });

  const validateMviewerUpload = (file) => {
    if (file.name) {
      return file.name.match(/.+\.mview$/);
    } else {
      return file.kind === 'file';
    }
  };

  return (
    <Fragment>
      {isEditing &&
        <Fragment>
          <span className="heading-6 mb--0">Marmoset Viewer Embed</span>
          <span className="builder-card-heading">Embed a single ".mview" file</span>
          {
            url &&
            <div className="widescreen">
              <iframe allowFullScreen src={`/marmoset_embed?url=${url}`} width="100%" style={{border: 'none'}}></iframe>
            </div>
          }
        </Fragment>
      }
      {!isEditing &&
        <Narrow
          narrow={isNarrow}
          toggleNarrow={() => onChange({ narrow: !isNarrow })}
        />
      }

      {errorNotice &&
        <ImageError message={errorNotice.message} />
      }

      {isEditing && !url &&
        <DropUploader
          label='Add .mview'
          limit={1}
          numFiles={url ? 1 : 0}
          onChange={(files) => onChange({ files })}
          onError={(error) => setError(error)}
          onUploadStart={onUploadStart}
          suffix={username}
          extensionList="MVIEW"
          verifyExtensionFn={validateMviewerUpload}
          maxMbUpload={25}
        />
      }

      {isEditing && url &&
        <div className="field-actions">
          <button
            className="button button--minWidth button--secondary"
            onClick={done}
          >
            Done
          </button>
        </div>
      }

      {!isEditing && url &&
        <div className={displayClassCss}>
          <div className="widescreen">
            <iframe allowFullScreen src={`/marmoset_embed?url=${url}`} width="100%" style={{border: 'none'}}></iframe>
          </div>
        </div>
      }
    </Fragment>
  );
};

MarmosetViewer.TYPE = 'marmoset-embed';
MarmosetViewer.initial = () => ({ url: null, narrow: true, type: MarmosetViewer.TYPE });
MarmosetViewer.update = (medium, { files, narrow }) => {
  if (typeof(narrow) !== 'undefined') {
    medium.narrow = narrow;
  }

  if (files) {
    medium.url = files[0].url
  }
}

export default MarmosetViewer;
