import React from 'react';
import { Icon } from 'src/components';
import YoutubeEmbed from './youtube-embed';

const Reorder = ({ content, remove, url, urls, variant, ...props }) => {
  const isImage = (path) => path.match(/\.(png|jpe?g)/i);

  return (
    <div className="builder-list">
      <div className="builder-list-reorder">
        <Icon symbol="reorder" />
      </div>
      <div className="builder-list-media">
      {url && isImage(url) && <img src={url} />}
      {urls && isImage(urls[0]) && <img src={urls[0]} />}
      {variant === "spacer" && <Icon symbol="spacer" />}
      {variant === "text" && <Icon symbol="text" />}
      {variant && variant.includes("gist") && <Icon symbol="social-github" />}
      {variant && variant.includes("sketchfab") && <Icon symbol="social-sketchfab" />}
      {variant && variant.includes("vimeo") && <Icon symbol="social-vimeo" />}
      {variant && variant.includes("youtube") && <img src={`https://img.youtube.com/vi/${props.link}/0.jpg`} />}
      {variant && variant.includes("marmoset") && <Icon symbol="marmoset" />}
      </div>
      <div className="builder-list-truncate">
        { variant === "image-row" && "Image Row" ||
          variant === "image-process" && "Process View" ||
          variant === "image" && "Image" ||
          variant === "text" && "Text: " ||
          variant && variant.includes("gist") && "GitHub Gist" ||
          variant && variant.includes("sketchfab") && "Sketchfab Model" ||
          variant && variant.includes("vimeo") && "Vimeo Video" ||
          variant && variant.includes("youtube") && "YouTube Video" ||
          variant === "spacer" && "Spacer" ||
          variant === 'marmoset-embed' && "Marmoset Embed"
        }
        {variant === "text" &&
          <span className="builder-list-description">
            {content.replace(/<\/?[^>]+(>|$)/g, " ").substr(0,40)}...
          </span>
        }
      </div>
      <button className="builder-list-delete" onClick={remove}>
        <Icon symbol="remove" />
      </button>
    </div>
  );
}

export default Reorder;
