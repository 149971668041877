const THIRTY_SECONDS = 1000 * 30;

const calculate = (endDate) => {
  const now = Math.round(new Date().getTime() / 1000);
  const timeLeft = endDate - now;

  if (timeLeft < 0) {
    document.querySelectorAll('.contest-stat-countdown').forEach((ele) => ele.remove());
    return;
  }

  // Calculate the time remaining DD:HH:MM
  const days = Math.floor(timeLeft / (60 * 60 * 24));
  const hours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((timeLeft % (60 * 60)) / 60);

  // Update the countdown display
  document.getElementById("contest-countdown-days").innerHTML = days;
  document.getElementById("contest-countdown-hours").innerHTML = hours.toString().padStart(2, '0');
  document.getElementById("contest-countdown-minutes").innerHTML = minutes.toString().padStart(2, '0');
}

export default (endDate) => {
  if (!endDate) {
    return;
  }

  calculate(+endDate);
  setInterval(() => calculate(+endDate), THIRTY_SECONDS);
}
