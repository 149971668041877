/****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
         IMPORTANT NOTE this is projects AND entries
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
 ****************
*/

import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc';
import { Icon } from 'src/components';

const domContainer = document.querySelector('#projects-ordering');

const DragHandle = SortableHandle(() => <Icon symbol="reorder" />);

const SortableItem = SortableElement(({ idx, type, name, imageUrl}) => {
  return (
    <div className="builder-list">
      <div className="builder-list-reorder">
        <DragHandle />
      </div>
      <div className="builder-list-media">
        <img src={imageUrl} />
      </div>
      <div className="builder-list-truncate">{name}</div>
    </div>
  );
});

const ProfileMediaOrderContainer = SortableContainer(({ projects }) => {
  return (
    <div className="builder-reorder">
      {projects.map(({ id, name, type, image_url }, index) =>
        <SortableItem
          id={id}
          name={name}
          type={type}
          imageUrl={image_url}
          index={index}
          key={index}
          useDragHandle={true}
        />
      )}
    </div>
  );
});


const ProfileMediaOrder = ({ projects: defaultProjects }) => {
  const [projects, setProjectData] = useState(defaultProjects)

  const onSortEnd = ({ newIndex, oldIndex }) => {
    return setProjectData((data) => {
      return arrayMove(data, oldIndex, newIndex);
    });
  };

  return (
    <Fragment>
      <input
        name="projects[sort_data]"
        type="hidden"
        value={JSON.stringify(projects.map(({ id, type }, index) => ({ id, type, rank: index })))}
      />
      <ProfileMediaOrderContainer projects={projects} onSortEnd={onSortEnd} />
    </Fragment>
  );
}

if (domContainer) {
  const projects = JSON.parse(domContainer.dataset.setup);
  ReactDOM.render(<ProfileMediaOrder projects={projects} />, domContainer);
}
