import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import { classNames, Icon, } from 'src/components';

const DetailedCheckBoxField = memo(({
  disabled,
  checked,
  value,
  name,
  label,
  onChange,
  className,
  errorMessage,
  link,
  linkText,
  linkTarget,
  hint,
  description,
}) => {
  const labelCss = classNames({
    'highlight': true,
    'highlight-disabled': disabled,
  });

  return (
    <label htmlFor={`detailed_checkbox_${value}`} className={labelCss}>
      <input
        type="checkbox"
        name={name}
        id={`detailed_checkbox_${value}`}
        value={value}
        className="highlight-input"
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />

      <div className="highlight-check">
        <Icon symbol="checkmark" size="S" />
      </div>

      <div className="highlight-backdrop"></div>
      <div className="highlight-content">
        <strong>{label}</strong>
        { description && <span>{description}</span> }
        { errorMessage && <div className="field-message field-message--error">{errorMessage}</div> }
      </div>
      {
        link && linkText &&
        <a href={link} target={linkTarget} className="highlight-link">{linkText}</a>
      }
    </label>
  );
})

DetailedCheckBoxField.displayName = 'DetailedCheckBoxFieldComponent';

DetailedCheckBoxField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
  linkTarget: PropTypes.string,
  hint: PropTypes.string,
  description: PropTypes.string,
  label: PropTypes.string.isRequired,
};

DetailedCheckBoxField.defaultProps = {
  linkTarget: '_blank',
  checked: false,
  disabled: false,
};

export default DetailedCheckBoxField;
