import React from 'react';
import Editor from 'react-medium-editor';

const MEDIUM_EMPTY_CONTENT = '<p><br></p>';

class Text extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: props.content,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.isEditing && nextProps.isEditing) {
      return this.state.content === '' ||
        this.state.content === MEDIUM_EMPTY_CONTENT ||
        nextState.content === '' ||
        nextState.content === MEDIUM_EMPTY_CONTENT ||
        this.props.content !== nextProps.content;
    } else {
      return true;
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.isEditing &&
          <label className="builder-card-heading">Add text below. Drag-select some text to display formatting options.</label>
        }
        {this.props.isEditing ?
          <React.Fragment>
            <div className="field">
              <Editor
                className="field-textarea field-textarea--editor"
                onChange={(text, medium) => this.setState({ content: text })}
                options={{toolbar: {buttons: ['h2', 'h3', 'bold', 'italic', 'anchor']}}}
                placeholder="Add Text"
                text={this.state.content}
              />
            </div>
            {this.state.content !== '' && this.state.content !== MEDIUM_EMPTY_CONTENT &&
              <div className="field-actions">
                <button
                  className="button button--minWidth button--secondary"
                  onClick={() => {
                    this.props.onChange(this.state.content);
                    this.props.done();
                  }}
                >Done</button>
              </div>
            }
          </React.Fragment>
          :
          <div
            className="builder-text"
            dangerouslySetInnerHTML={{ __html: this.state.content }}
          />
        }
      </React.Fragment>
    );
  }
};

Text.TYPE = 'text';
Text.initial = () => ({content: '', type: Text.TYPE});
Text.update = (medium, value) => medium.content = value;

export default Text;
