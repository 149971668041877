import React from 'react';
import ReactDOM from 'react-dom';
import Autosuggest from 'react-autosuggest';

class SingleAutocomplete extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      value: props.value,
      all: JSON.parse(props.suggestions) || [],
      suggestions: []
    };

    this.autocomplete = React.createRef();
    this.getSuggestions = this.getSuggestions.bind(this);
    this.getSuggestionValue = this.getSuggestionValue.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
  }

  getSuggestions(value) {
    const inputValue = value.value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : this.state.all.filter(s =>
      s.toLowerCase().slice(0, inputLength) === inputValue
    ).slice(0,4);
  };

  getSuggestionValue(suggestion) {
    return suggestion;
  }

  renderSuggestion(suggestion) {
    return(
      <div>
        {suggestion}
      </div>
    );
  }

  onChange(event, { newValue }) {
    this.setState({ value: newValue });
  }

  onSuggestionsFetchRequested(value) {
    this.setState({ suggestions: this.getSuggestions(value) });
  };

  onSuggestionsClearRequested() {
    this.setState({ suggestions: [] });
  };

  onSuggestionSelected() {
    const { autosubmit } = this.props;

    if (autosubmit.toString() === 'true') {
      setTimeout(() => {
        this.autocomplete.current.input.form.submit();
      }, 200);
    }
  }

  render () {
    const { value, suggestions } = this.state;
    const { placeholder } = this.props;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: placeholder || "Type your school's name",
      value: value,
      onChange: this.onChange,
      name: this.props.field
    };

    // Finally, render it!
    // onSuggestionSelected
    // <input type={'hidden'} name={this.props.field} value={this.state.value}/>

    return (
      <React.Fragment>
        <Autosuggest
          ref={this.autocomplete}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={inputProps}
          theme={{
            suggestionsContainer:     'profile-school',
            suggestionsList:          'profile-school-suggestion',
            suggestion:               'profile-school-suggestion-item',
            container:                'profile-schoolContainer',
            containerOpen:            'react-autosuggest__container--open',
            input:                    'react-autosuggest__input',
            inputOpen:                'react-autosuggest__input--open',
            inputFocused:             'react-autosuggest__input--focused',
            suggestionsContainerOpen: 'profile-school--open',
            suggestionFirst:          'react-autosuggest__suggestion--first',
            suggestionHighlighted:    'profile-school-suggestion-item--highlighted',
            sectionContainer:         'react-autosuggest__section-container',
            sectionContainerFirst:    'react-autosuggest__section-container--first',
            sectionTitle:             'react-autosuggest__section-title'
          }}
        />
      </React.Fragment>
    );
  }
}

const containers = document.querySelectorAll('.single-autocomplete');

Array.prototype.forEach.call(containers, container => {
  ReactDOM.render(<SingleAutocomplete {...(container.dataset)} />, container);
});
