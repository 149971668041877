import React from 'react';
import Assets from 'rails_assets';

const Empty = ({ wip }) => {
  return (
    <div className="builder-empty">
      <img src={Assets['illustrations/hand-blocks.svg']} className="forcePaint" alt="" />
      {wip ?
        <h3 className="heading-4">Add more content to your entry page</h3>
      :
        <h3 className="heading-4">Add content to your entry page</h3>
      }
    </div>
  );
}

export default Empty;
