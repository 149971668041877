import React, { useState, useEffect } from 'react';
import { csrfToken } from 'src/utils';
import Button from './button';
import Assets from 'rails_assets';
import classNames from 'classnames';

const HighFiveButton = ({ type: defaultType, id: detaultId, modelId: defaultModelId }) => {
  const [type, setType] = useState(defaultType);
  const [id, setId] = useState(detaultId);
  const [modelId, setModelId] = useState(defaultModelId);
  const [isLoading, setLoading] = useState(false);
  const [hasError, setErrored] = useState(false);
  const [isTracked, setTrackingState] = useState(false);

  const remove = () => {
    fetch(`/high_fives/${modelId}`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      }
    }).then(
      (response) => response.json()
    ).then((data) => {
      setModelId(null);
      setLoading(false);
    }).catch((ex) => {
      setErrored(true);
      setLoading(false);
    });
  };

  const add = () => {
    fetch(`/high_fives`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken()
      },
      body: JSON.stringify({
        high_five: {
          high_fiveable_type: type,
          high_fiveable_id: id
        }
      })
    }).then(
      (response) => response.json()
    ).then(({ data }) => {
      setModelId(data.id);
      setLoading(false);

      if (!isTracked && typeof(RookiesTracking) !== 'undefined') {
        RookiesTracking.trackMilestone(
          'high fived',
          { highFiveTypeClass: type, highFiveTypeId: id }
        );
        setTrackingState(true); // avoid slamming the events
      }
    }).catch((ex) => {
      setErrored(true);
      setLoading(false);
    });
  };

  const onButtonSubmit = (e) => {
    e.preventDefault();

    if (isLoading) { return; }

    setLoading(true);
  };

  useEffect(() => {
    /* Fetch */
    if (isLoading) {
      modelId ? remove() : add();
    }
  }, [isLoading]);

  const selected = !!modelId;
  const btnClasses = classNames({
    button: true,
    'button--auto': true,
    'button--destructive': !!hasError,
  });

  return (
    <Button
      onClick={onButtonSubmit}
      type="button"
      className={btnClasses}
      symbol={hasError ? 'info' : (isLoading ? null : (selected ? 'unfive' : 'high-five'))}
      disabled={hasError || isLoading}
    >
      {isLoading && <img className="icon icon--S" src={Assets['photoswipe/preloader.gif']} alt="Loading" />}
      {hasError ? 'Error' : (isLoading ? 'Loading' : (selected ? 'Unfive' : 'High Five!'))}
    </Button>
  );
};

export default HighFiveButton;
