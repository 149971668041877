import React from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';

import { Icon } from 'src/components';

const DragHandle = SortableHandle(() => <Icon symbol="reorder" />);

const SortableItem = SortableElement(({idx, removeItem, url}) => {
  const filename = url.substring(url.lastIndexOf('/')+1);
  return (
    <div className="builder-list">
      <div className="builder-list-reorder">
        <DragHandle />
      </div>
      <div className="builder-list-media">
        <img src={url} />
      </div>
      <div className="builder-list-truncate">{filename}</div>
      <button
        onClick={() => removeItem(idx)}
        className="builder-list-delete"
        type="button">
        <Icon symbol="remove" />
      </button>
    </div>
  );
});

const ImagesEdit = SortableContainer(({removeItem, urls}) => {
  return (
    <div className="builder-reorder">
      {urls.map((url, index) =>
        <SortableItem idx={index} index={index} key={index} removeItem={removeItem} url={url} useDragHandle={true} />
      )}
    </div>
  );
});

export default ImagesEdit;
