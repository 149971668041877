import 'promise-polyfill/src/polyfill';

const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  });

export default function getCroppedImg(url, pixelCrop) {
  return new Promise((resolve, reject) => {
    createImage(url).then((image) => {
      const canvas = document.createElement('canvas');
      canvas.width = pixelCrop.width;
      canvas.height = pixelCrop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        pixelCrop.width,
        pixelCrop.height
      );

      canvas.toBlob(blob => {
        const segments = url.split('/');
        const parts = segments[segments.length - 1].split('.');
        parts[0] = `${parts[0]}-crop`;
        blob.name = parts.join('.');
        resolve(blob);
      }, 'image/jpeg');
    });
  });
}
