import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch';
import { parseString } from 'xml2js';

export default function(files, filenameSuffix = '', credentialURL = '/uploads') {
  return fetch(credentialURL, {
    credentials: 'same-origin'
  }).then(function(response) {
    if (response.ok) {
      return response.json();
    }
    throw new Error(response.statusText);
  }).then(function({ credentials, url }) {
    return Promise.all(Array.prototype.map.call(files, file => {
      const formData = new FormData();

      for (var key in credentials) {
        if (credentials.hasOwnProperty(key)) {
          formData.append(key, credentials[key]);
        }
      }
      let name = file.name;
      if (filenameSuffix.length > 0) {
        const parts = file.name.split('.');
        parts[0] = `${parts[0]}-${filenameSuffix}`;
        name = parts.join('.');
      }
      formData.append('Content-Type', file.type);
      formData.append('Content-Disposition', `inline; filename="${name}"`);
      formData.append('Cache-Control', 'public, max-age=31536000');
      formData.append('file', file, name);

      return fetch(url, {
        body: formData,
        method: 'POST'
      }).then(function(response) {
        return response.text();
      }).then(function(xml) {
        return new Promise((resolve, reject) => {
          parseString(xml, function (err, result) {
            if(err) {
              reject(err);
              return;
            }
            resolve(result['PostResponse']['Location'][0]);
          });
        });
      }).then(function(url) {
        return url.replace(/%2f/gi, '/');
      }).then(function(url) {
        file.url = url
        return file;
      });
    }));
  });
}
