import React from 'react';
import ReactDOM from 'react-dom';
import ContestGroups from '../contest_selection';

const contestSelectionApp = document.querySelector('#contest-selection-app');

if (contestSelectionApp) {
  const setup = JSON.parse(contestSelectionApp.getAttribute('data-setup'));
  ReactDOM.render(<ContestGroups {...setup} />, contestSelectionApp);
}
