import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import useCropAndUpload from 'src/hooks/use_crop_and_upload';
import { onDomReady } from 'src/utils';

import { DropUploader, CropUploader, Button, ImageError } from 'src/components';

const ThumbnailUploader = ({ url: initialUrl, onChange, username }) => {
  const {
    error,
    url,
    isCropping,
    isEditing,
    setEditing,
    onError,
    onCropComplete,
    onUploadStart,
    onUploadComplete
  } = useCropAndUpload({
    initialUrl,
    onChange
  });

  const showCancel = url && isEditing && !isCropping;
  const showEdit = !isEditing && !isCropping;
  const showUploader = isEditing;
  const showCropper = isCropping;
  const showImage = url && !showCropper && !showUploader;

  return (
    <div className={`builder-card ${showImage ? 'is-filled' : 'is-editing'}`}>
      { error &&
        <ImageError message={error.message} />
      }

      { showCancel &&
        <div className="builder-card-actions">
          <Button onClick={() => setEditing(false)} symbol="x" tooltip="Cancel" />
        </div>
      }

      { showEdit &&
        <div className="builder-card-actions">
          <Button onClick={() => setEditing(true)} symbol="edit" tooltip="Edit" />
        </div>
      }

      { showImage &&
        <img crossOrigin="anonymous" src={url} />
      }

      { showUploader &&
        <DropUploader
          expanded={true}
          label='Add Featured Thumbnail (required)'
          limit={1}
          onChange={onUploadComplete}
          onError={onError}
          onUploadStart={onUploadStart}
          suffix={username}
        />
      }

      { showCropper &&
        <Fragment>
          <h4 className="builder-card-heading mt--0">
            Crop and reposition your project thumbnail
          </h4>
          <CropUploader
            aspect={4/3}
            onChange={onCropComplete}
            url={url}
          />
        </Fragment>
      }
    </div>
  );
}

onDomReady(() => {
  const containers = document.querySelectorAll('.thumbnail-uploader-container');

  Array.prototype.forEach.call(containers, container => {
    const username = container.getAttribute('data-username');
    const target = document.querySelector(container.getAttribute('data-target'));
    const buttons = document.querySelectorAll(container.getAttribute('data-target-submit-buttons'));

    if (target) {
      const onChange = (url, isDone) => {
        target.value = url;

        if (isDone) {
          Array.prototype.forEach.call(buttons, (btn) => {
            btn.disabled = false;
          });
        } else {
          Array.prototype.forEach.call(buttons, (btn) => {
            btn.disabled = true;
          });
        }
      };

      ReactDOM.render(
        <ThumbnailUploader
          onChange={onChange}
          url={target.value}
          username={username}
        />,
        container
      );
    }
  });
});
