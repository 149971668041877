import React, { Fragment } from 'react';
import Narrow from './narrow';

class VimeoEmbed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      link: props.link,
      password: props.password,
      narrow: props.narrow
    }

    this.toggleNarrow = this.toggleNarrow.bind(this);
  }

  value() {
    if (this.state.link) {
      return `https://vimeo.com/${this.state.link}`;
    } else {
      return "";
    }
  }

  extract_id(url) {
    try {
      if (!!url.match(/vimeo\.com\/([0-9A-Za-z]+)/)) {
        return url.match(/vimeo\.com\/([0-9A-Za-z]+)/).pop();
      } else {
        return url;
      }

    } catch(error) {
      return "";
    }
  }

  password() {
    if (this.props.entry) {
      return (
        <div>
          <span className="heading-6 mb--0">Embed Vimeo</span>
          <span className="builder-card-heading">Is your video in a Film Festival? Provide the password for judge access.</span>
          <div className="field">
            <input
              className="field-input"
              onChange={(e) => this.setState({ password: e.target.value }, () => this.props.onChange(this.state)) }
              placeholder="Optional video password"
              value={this.state.password}
             />
          </div>
        </div>
      )
    } else {
        return "";
    }
  }

  toggleNarrow() {
    const newState = !this.state.narrow;
    this.setState(
      {narrow: newState},
      () => this.props.onChange(this.state)
    );
  }

  onKeyDown(e) {
    if (e.keyCode == 13) {
      e.preventDefault();
      this.props.done();
    }
  }

  render() {
    return (
        <Fragment>
          {this.props.isEditing ?
            <Fragment>
              <span className="heading-6 mb--0">Embed Vimeo</span>
              <span className="builder-card-heading">Add a Vimeo url to embed video.</span>
              <div className="field">
                <input
                  className="field-input"
                  onKeyDown={this.onKeyDown.bind(this)}
                  onChange={(e) => this.setState({ link: this.extract_id(e.target.value) }, () => this.props.onChange(this.state)) }
                  placeholder="e.g. https://vimeo.com/123456789"
                  value={this.value()}
                  autoFocus={true}
                />
              </div>
              { this.password() }
              {this.props.content !== '' &&
                <div className="field-actions">
                  <button
                    className="button button--minWidth button--secondary"
                    onClick={this.props.done}
                  >Done</button>
                </div>
              }
            </Fragment>
            :
            <Fragment>
              <Narrow narrow={this.state.narrow} toggleNarrow={this.toggleNarrow} />
              <div className={`builder-embed${this.state.narrow ? ' builder-narrow' : ''}`}>
                <iframe src={"https://player.vimeo.com/video/" + this.state.link } />
              </div>
            </Fragment>
          }
        </Fragment>
    );
  }
};

VimeoEmbed.TYPE = 'vimeo-embed';
VimeoEmbed.initial = () => ({link: '', password: '', narrow: true, type: VimeoEmbed.TYPE});
VimeoEmbed.update = (medium, { link, password, narrow }) => {
  medium.link = link;
  medium.password = password;

  if (typeof(narrow) !== 'undefined') {
    medium.narrow = narrow;
  }
}

export default VimeoEmbed;
